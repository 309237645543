import React from 'react'
import Contact from "../components/Contact";

export default function contacto() {
    return (
        
            <Contact />
        
    )
}
