import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import instagramlogo from "../../assets/images/icons/instagramlogo.png"
import twitterlogo from "../../assets/images/icons/twitterlogo.png"
import blasfemialogo from "../../assets/images/logos/logoWhite.png"
import "../styles/FooterPage.css";

export default function Footer() {
    return (
        <footer>
            <Container fluid className="d-none d-md-block">
                <Row className="d-none d-md-flex">
                    <Col md={3} className="d-flex justify-content-center mt-2">
                        <p className="separationterms ">BEBER CON MODERACIÓN Y EN CASA. <br/> PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. <br/> No compartir este contenido con menores.</p>
                    </Col>
                    <Col  md={6} className="d-flex justify-content-center mt-2">
                        <Row>
                        <a href={"../../public/docs/TerminosyCondiciones.pdf"} target="_blank" download  rel="noreferrer"><p className="separationterms ">TERMINOS Y CONDICIONES</p></a>
                        <a href={"../../public/docs/PoliticasdePrivacidad.pdf"} target="_blank" download  rel="noreferrer"><p className="separationterms">POLITICAS DE PRIVACIDAD</p></a>
                        <a href={"https://www.cerveceriaymalteriaquilmes.com/"} target="_blank"  rel="noreferrer"><p className="separationterms">INFORMACIÓN DE LA COMPAÑIA</p></a> 
                        </Row>
                    </Col>
                    <Col md={3}>
                        <div className="text-center">
                            <Image src={instagramlogo} alt="logo instagram" className="mr-5 click" onClick={() => window.location.replace("https://www.instagram.com/blasfemia.arg/")}/>
                            <Image src={twitterlogo} alt="logo twitter" className="click" onClick={() => window.location.replace("https://twitter.com/argblasfemia?lang=esfirebase deploy")} />
                        </div>
                    </Col>
                </Row>
                </Container>
                <Container className="d-block d-md-none" >
                        <Row className="d-flex justify-content-center mb-2">
                            <Col xs={6} className="text-center">
                                <div>
                                    <Image src={blasfemialogo} width={170} alt="" />
                                </div>
                            </Col>
                            <Col xs={6} className="text-center">
                                    <Image src={instagramlogo} alt="logo instagram" className="mr-4 click" onClick={() => window.location.replace("https://www.instagram.com/blasfemia.arg/")}/>
                                    <Image src={twitterlogo} alt="logo twitter" className="click" onClick={() => window.location.replace("https://twitter.com/argblasfemia?lang=esfirebase deploy")}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mt-4">
                                <Row className="d-flex justify-content-center text-center">
                                    <Col xs={5} className="p-0 m-0">
                                        <a href={"../../public/docs/TerminosyCondiciones.pdf"} target="_blank" rel="noreferrer" download><p className="termsfootermobile p-0 m-0 ">TERMINOS Y CONDICIONES</p></a>
                                    </Col>
                                    <Col xs={1} className="termsfootermobile p-0 m-0">
                                    |
                                    </Col>
                                    <Col xs={5} className="p-0 m-0">
                                    <a href={"../../public/docs/TerminosyCondiciones.pdf"} target="_blank" rel="noreferrer" download><p className="termsfootermobile p-0 m-0">POLITICAS DE PRIVACIDAD</p></a>
                                    </Col>
                                    <Col xs={12} className="p-0 my-1">
                                    <a href={"https://www.cerveceriaymalteriaquilmes.com/"} target="_blank" rel="noreferrer"><p className="termsfootermobile p-0 m-0">INFORMACIÓN DE LA COMPAÑIA</p></a>
                                    </Col>
                                </Row>
                        </Row>
                        <Row className="d-flex justify-content-center mt-2 text-center">
                            <p className="terms18mobile ">BEBER CON MODERACIÓN Y EN CASA. <br/> PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.<br/> No compartir este contenido con menores.</p>
                        </Row>
                </Container>
            
        </footer>
    )
}
