import React from "react";
import { Container, Row, Image, Form, Button } from "react-bootstrap";
import Image10 from "../../assets/images/page_contact/A9712.png";
import ContainerStyles from "../styles/Contact.module.css";
import Footer from './FooterPage';
import Header from "./Header";

export default function Contact() {
  return (
    <div className={ContainerStyles.bg}>
      <Header activeItem='contacto' />
      <div className={ContainerStyles.footercontanct +  " d-flex" }>
        <div className={ContainerStyles.halfscreen}>
          <Image src={Image10} alt="" />
        </div>
        <div className={ContainerStyles.halfscreen1}>
          <Container>
            <Row  className={ContainerStyles.form +" d-flex flex-column"}>
              <h1 className={ContainerStyles.titleCt}>
                Queridx blasfemx, <br /> estemos en contacto.
              </h1>
              <p className={ContainerStyles.subtitleCt}>
                ¿Querés decirnos algo? ¿Querés trabajar con nosotrxs? ¿Querés
                <br /> tener noticias nuestras, o ser embajadorx de Blasfemia?
                Decinos <br /> lo que quieras dejando tus datos acá.
              </p>
              <Form onSubmit={e => e.preventDefault()}>
                <Form.Group controlId="formName" className="mb-0">
                  <Form.Label className={ContainerStyles.labelCt}>Nombre y apellido *</Form.Label>
                  <Form.Control className={ContainerStyles.inputCt} type="text"/>
                </Form.Group>
                <Form.Group className="mb-0" controlId="formAge">
                  <Form.Label className={ContainerStyles.labelCt +" mt-0 pt-0" }>Edad</Form.Label>
                  <Form.Control className={ContainerStyles.inputCt} type="text" />
                </Form.Group>
                <Form.Group controlId="formAddress"  className="mb-0">
                  <Form.Label className={ContainerStyles.labelCt}>Dónde vivis? *</Form.Label>
                  <Form.Control className={ContainerStyles.inputCt} type="text" />
                </Form.Group>
                <Form.Group controlId="formEmail"  className="mb-0">
                  <Form.Label className={ContainerStyles.labelCt}>Mail *</Form.Label>
                  <Form.Control className={ContainerStyles.inputCt} type="email" />
                </Form.Group>
                <Form.Group controlId="formmsj"  className="mb-0">
                  <Form.Label className={ContainerStyles.labelCt}>Tu mensaje *</Form.Label>
                  <Form.Control className={ContainerStyles.inputCt} as="textarea" rows={3} />
                </Form.Group>
                <Button
                  type="submit"
                  variant="warning"
                  className={ContainerStyles.btnSend + " rounded rounded-pill"}
                >
                  Enviar
                </Button>
              </Form>
            </Row>
          </Container>
        </div>
      </div>
      <div className={ContainerStyles.footer}>
        <Footer />
      </div>
    </div>
  );
}